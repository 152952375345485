import './App.css';
import { Navigate, Route, Routes } from 'react-router-dom';
import Home from './Components/Home/Home';
import ImageLibrary from './Components/ImageLibrary/ImageLibrary';
import Navbar from './Components/Navbar/Navbar';
import Login from './Components/Login/Login';
import axios from 'axios';
import EmailBuilder from './Components/EmailBuilder/EmailBuilder';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { MainTheme } from './Themes/MainTheme';
import ContactToEntity from './Components/EntityManager/ContactToEntity/ContactToEntity';
//import AS400DescriptionManager from './Components/ProductDataManager/AS400DescriptionManager/AS400DescriptionManager';
import UserCalendar from './Components/Calendar/UserCalendar';
import LoginCheckRoute from './Routes/LoginCheckRoute';
import Handshake from './Components/Handshake/Handshake';
import usePageTitle from './Components/_GlobalFunctions/PageTitle';
//import DragAndDrop from './Components/ProductDataManager/DragAndDrop/DragAndDrop';
import ProductDataManagerRoute from './Routes/ProductDataManagementRoute';
import HelpDeskTools from './Components/HelpDeskTools/HelpDeskTools';
import { useDispatch, useSelector } from 'react-redux';
import AccountNotes from './Components/Account Notes/AccountNotes';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import EntityManager from './Components/EntityManager/EntityManager';
import MarketingPromotions from './Components/MarketingPromotions/MarketingPromotions';
import ImageManagement from './Components/ImageManagement/ImageManagement';
import ProductDataManagementRoute from './Routes/ProductDataManagementRoute';
import EntityRoutes from './Routes/EntityRoutes';
import PublishItems from './Components/PublishItems/PublishItems';
import LoadingSpinner from './Components/_GlobalComponents/LoadingSpinner';
import { useEffect } from 'react';
import { setIsLoading } from './Redux/LoadingSlice';
import MatchingGame from './Components/MatchingGame/MatchingGame';
import ManufacturerRoute from './Routes/ManufacturerRoute';
import PublicationsRoute from './Routes/PublicationsRoute';
import Exhibitors from './Components/Exhibitors/Exhibitors';
import Attendees from './Components/Attendees/Attendees';
import EntityViewer from './Components/EntityViewer/EntityViewer';
import Badges from './Components/Badges/Badges';
import MarketingRoutes from './Routes/MarketingRoutes';

function App() {
  if (window.location.host !== 'localhost:3000') {
    axios.defaults.baseURL = 'https://node.sportssouth.dev';
  }

  const userPermission = useSelector((state) => state.userPermission.value);

  const defaultTheme = createTheme(MainTheme);

  const pageTitle = usePageTitle();

  const dispatch = useDispatch();

  useEffect(() => {
    const beforeUnload = () => {
      dispatch(setIsLoading(false));
    };

    window.addEventListener('beforeunload', beforeUnload);

    return () => {
      window.removeEventListener('beforeunload', beforeUnload);
    };
  }, [dispatch]);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <ThemeProvider theme={defaultTheme}>
        <LoadingSpinner />
        <Routes>
          <Route path='/matching-game' element={<MatchingGame />} />
          <Route path='/login' element={<Login />} />
          <Route
            element={
              <LoginCheckRoute>
                <Navbar />
              </LoginCheckRoute>
            }
          >
            <Route index element={<Home />} />
            <Route path='/calendar' element={<UserCalendar />} />
            <Route path='/image-library' element={<ImageLibrary />} />
            <Route path='/email-builder' element={<EmailBuilder />} />

            <Route path='/handshake' element={<Handshake />} />
            {/*<Route path='/drag-and-drop' element={<DragAndDrop />} />*/}
            <Route path='/help-desk-tools' element={<HelpDeskTools />} />
            <Route path='/account-notes' element={<AccountNotes />} />
            {userPermission[900] && (
              <Route path='/sales-promotions' element={<MarketingPromotions />} />
            )}
            <Route path='/image-management' element={<ImageManagement />} />
            {userPermission[961] && <Route path='/publish-items' element={<PublishItems />} />}
            {userPermission[968] && <Route path='/exhibitors' element={<Exhibitors />} />}
            {userPermission[969] && <Route path='/attendees' element={<Attendees />} />}
            {userPermission[970] && <Route path='/badges' element={<Badges />} />}
            {userPermission[971] && <Route path='/directory' element={<EntityViewer />} />}

            {/* MARKETING | DATA | PUBLICATIONS ETC ROUTES */}
            {/*<Route path='/pdm/as400-description-management' element={<AS400DescriptionManager />} />*/}
            <Route path='/pdm/*' element={<ProductDataManagementRoute />} />
            <Route path='/entity/*' element={<EntityRoutes />} />
            <Route path='/manufacturer/*' element={<ManufacturerRoute />} />
            <Route path='/publications/*' element={<PublicationsRoute />} />
            {/*<Route path='/pdm/*' element={<ProductDataManagement />} /> */}
            <Route path='/product-data/*' element={<ProductDataManagerRoute />} />
            <Route
              path='/entity-manager/entity-maintenance'
              element={<h1>Entity maintenance</h1>}
            />
            <Route path='/entity-manager/*' element={<EntityManager />} />
            <Route path='/entity-manager/contact-to-entity' element={<ContactToEntity />} />
            <Route path='/marketing/*' element={<MarketingRoutes />} />

            {/* GLOBAL WILDCARD ROUTE */}
            <Route path='*' element={<Navigate to='/' replace />} />
          </Route>
        </Routes>
      </ThemeProvider>
    </LocalizationProvider>
  );
}

export default App;
