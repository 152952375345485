import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box } from '@mui/material';
import MainButtonStack from './components/MainButtonStack';
import ContractForm from '../Contracts/components/ManageContracts/components/ContractForm';
import ContractFormMobile from '../Contracts/components/ManageContracts/components/ContractFormMobile';

const Manufacturers = () => {
  const navigate = useNavigate();

  return (
    <Box sx={{ height: '100%', backgroundColor: '#e7e7e7', overflow: 'hidden' }}>
      {/* <MainButtonStack /> */}
      {/* <ContractForm userid={54155} /> */}
      {/* <ContractFormMobile userid={54155} /> */}
      {[...Array(100)].map((e) => (
        <p key={e} style={{ width: '300px' }}>
          {e}lkasjd flksjf lksj flks flksjdnf lsdjf lksdj flksj dfls fdlksj df
        </p>
      ))}
    </Box>
  );
};

export default Manufacturers;
