import React, { useContext, useEffect, useState } from "react";
import { ProductDataContextState } from "../../../Context/Marketing/ProductData/ProductDataContext"; 
import { Autocomplete, Breadcrumbs, Button, ButtonGroup, Drawer, Grid2, Link, Paper, Stack, TextField, Typography } from "@mui/material";
import styled from "styled-components";

export default function ProductDataDashboard(){
    // CONTEXT STATE
    const { userId, products } = useContext(ProductDataContextState)

    // LOCAL STATE
    const [item, setItem] = useState({})
    const [drawerOpen, setDrawerOpen] = useState(false)

    // USE EFFECT

    useEffect(() => {
        const fetch =  () => {
            setItem(products.find(item => item.product_code === '148355'))            
        }
        fetch()
    }, [products])
    //console.log(item)
    //return(<>Empty:   {item?.description}</>)

    // VARIABLES

    const DashboardCard = styled(Paper)(({ theme }) => ({
        backgroundColor:'whitesmoke',
        textAlign:'center',
        margin:1,
        padding:5

    }))
    const drawerOptionMargin = 3
    const maxWidgetWidth = 500

    //PLACEHOLDER VARIABLES

    const BrandVal = [
        {label: 'Placeholder1', id:1},
        {label: 'Placeholder2', id:2},
        {label: 'Placeholder3', id:3}
    ]

    const CatVal = [
        {label: 'Placeholder1', id:1},
        {label: 'Placeholder2', id:2},
        {label: 'Placeholder3', id:3}
    ]

    const DeptVal = [
        {label: 'Placeholder1', id:1},
        {label: 'Placeholder2', id:2},
        {label: 'Placeholder3', id:3}
    ]

    //TRIGGERS

    const openDrawer = () => {
        setDrawerOpen(true)
    }

    const closeDrawer = () => {
        setDrawerOpen(false)
    }

    return(<div style={{paddingTop:'5px'}}>
        
        <Breadcrumbs separator=">">
            <Link
                padding={2}
                underline="hover"
                color="primary"
            >
                Dashboard
            </Link>
        </Breadcrumbs>
        <div style={{textAlign:"right", paddingRight:20}}>
        <Button onClick={openDrawer} sx={{ flexDirection:'row-reverse'}}>Options</Button>
        </div>
        <Grid2 
            container 
            spacing={2} 
            columns={{xs: 6, md: 9}}
            alignItems={'center'} 
            display={'flex'} 
            justifyContent={'center'}
        >
            <Grid2 size={3} border={2} borderRadius={2} margin={1} boxShadow={5}>
                <DashboardCard>
                    <Typography fontWeight={'bold'}>
                        PLACEHOLDER
                    </Typography>
                    <Stack direction={'row'} spacing={3} justifyContent={'center'} alignItems={'center'}>
                        <TextField variant="outlined"/>
                        <Button variant="contained">TEST</Button>
                    </Stack>
                </DashboardCard>
            </Grid2>
            <Grid2 size={3} border={2} borderRadius={2} margin={1} boxShadow={5}>
                <DashboardCard>
                    <Typography fontWeight={'bold'}>
                        Quicknav PLACEHOLDER
                    </Typography>
                    <Link>
                        Quicklink
                    </Link>
                </DashboardCard>
            </Grid2>
            <Grid2 size={3} border={2} borderRadius={2} margin={1} boxShadow={5}>
                <DashboardCard>
                    <Typography fontWeight={'bold'}>
                        INFO PLACEHOLDER
                    </Typography>
                    <Stack direction={'column'} spacing={3} justifyContent={'center'} alignItems={'center'}>
                        <Typography>Info 1</Typography>
                        <Typography>Info 2</Typography>
                    </Stack>

                </DashboardCard>
            </Grid2>
            <Grid2 size={3} border={2} borderRadius={2} margin={1} boxShadow={5}>
                <DashboardCard>
                    <Typography fontWeight={'bold'}>
                        PLACEHOLDER
                    </Typography>
                    <Stack direction={'row'} spacing={3} justifyContent={'center'} alignItems={'center'}>
                        <TextField variant="outlined"/>
                        <Button variant="contained">TEST</Button>
                    </Stack>

                </DashboardCard>
            </Grid2>
        </Grid2>
        <Drawer open={drawerOpen} onClose={closeDrawer}>
            <TextField label='Search for SS#-UPC-MFR#-SCAN ' variant="outlined"/>
            <ButtonGroup orientation="vertical" variant="text" sx={{paddingLeft:2, paddingRight:2}}>
                <Button>
                    Uncategorized Items
                </Button>
                <Button>
                    Unattributed Items
                </Button>
                <Button>
                    Worn & Dented
                </Button>
                <Button>
                    My Flagged Items
                </Button>
                <Button>
                    All Flagged Items
                </Button>
                <Button>
                    Items w/ Few Attributes
                </Button>
                <Button>
                    Items Without Country set
                </Button>
                <Button>
                    Missing True Value
                </Button>
                <Button>
                    Missing Max Capacity
                </Button>
            </ButtonGroup>
            <TextField label='Search For Recently Worked' variant="outlined"/>
            <Autocomplete
                sx={{marginTop: drawerOptionMargin}}
                options={CatVal}
                renderInput={(params) => <TextField {...params} label="Category"/>}
            />
            <Autocomplete                
                sx={{marginTop: drawerOptionMargin}}
                options={BrandVal}
                renderInput={(params) => <TextField {...params} label="Brand"/>}
            />
            <Autocomplete                
                sx={{marginTop: drawerOptionMargin}}
                options={DeptVal}
                renderInput={(params) => <TextField {...params} label="Department"/>}
            />
            <Button sx={{margin:3}} variant="contained">Logo Manager</Button>
            <Button sx={{margin:3}} variant='contained'>Value Corrections</Button>
        </Drawer>
    </div>)
}