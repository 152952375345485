import { Box, Container, Grid2 } from '@mui/material';
import React from 'react';

import SideNav from './components/SideNav/SideNav';
import PublicationList from './components/PublicationList';
import MediaKitContext from './MediaKitContext';
import MobileFilter from './components/SideNav/MobileFilter';
import JsonXlsx from './components/JsonXlsx';

const MediaKit = () => {
  return (
    <MediaKitContext>
      {/* <JsonXlsx /> */}

      <Container
        className='MediaKitMarker'
        maxWidth='xl'
        sx={{ pt: 4, height: '100%', overflow: 'hidden' }}
      >
        <Grid2
          className='MediaKitGridContainer'
          container
          sx={{ backgrondColor: 'teal', height: '100%', overflow: 'hidden' }}
          spacing={3}
        >
          <Grid2 size={{ xs: 12, md: 4, lg: 3 }} sx={{ display: { xs: 'none', md: 'block' } }}>
            <SideNav />
          </Grid2>
          <Grid2
            className='here-100'
            size={{ xs: 12, md: 8, lg: 9 }}
            sx={{ height: '100%', overflowY: 'auto', pb: 6 }}
          >
            <Box>
              <PublicationList />
            </Box>
          </Grid2>
        </Grid2>
      </Container>

      {/* MOBILE NAV CONTAINER */}
      <MobileFilter />
    </MediaKitContext>
  );
};

export default MediaKit;
