import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { useSelector } from 'react-redux';
import EntityContext from '../Context/Entity/EntityContext';
import Manufacturers from '../Components/Manufacturers/Manufacturers';
import Advertising from '../Components/Manufacturers/components/Advertising/Advertising';
import Dashboard from '../Components/Manufacturers/components/Dashboard/Dashboard';
import MediaKit from '../Components/Manufacturers/components/MediaKit/MediaKit';
import PublicationContextV2 from '../Context/Marketing/PublicationContextV2';
import PublicationLandingPage from '../Components/Manufacturers/components/MediaKit/components/PublicationLandingPage/PublicationLandingPage';
const EntityRoutes = () => {
	//const userPermission = useSelector((state) => state.userPermission.value);

	return (
		<EntityContext>
			<PublicationContextV2>
				<Routes>
					<Route path='/product-audit' element={<h2>/product-audit</h2>} />
					<Route path='/marketing-opportunities' element={<h2>/marketing-opportunities</h2>} />
					<Route path='/product-performance' element={<h2>/product-performance</h2>} />
					<Route path='/advertising' element={<Advertising />} />
					<Route path='/dashboard' element={<Dashboard />} />
					<Route path='/media-kit' element={<MediaKit />} />
					<Route path='/media-kit/:pub_id' element={<PublicationLandingPage />} />
					<Route path='/*' element={<Manufacturers />} />
				</Routes>
			</PublicationContextV2>
		</EntityContext>
	);
};

export default EntityRoutes;
