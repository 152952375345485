import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';

// CONTEXT STATE
export const ProductDataContextState = React.createContext({
  //USER
  userId: 0,
  products: [],
  getAllProducts: () => {},
  updateUserId: () => {},
  getDesiredSkuInfo: () => {},
  allDepartments: [],
  allCategories: [],
  allManufacturers: [],
});

// PRIMARY COMPONENT
const ProductDataContext = ({ children }) => {
  // LOCAL STATE
  const [loading, setLoading] = useState(true);
  const [userIdState, setUserIdState] = useState(54156);
  const [getAllProductsState, setAllProductsState] = useState([]);
  const [allDepartmentsState, setAllDepartmentsState] = useState([]);
  const [allCategoriesState, setAllCategoriesState] = useState([]);
  const [allManufacturersState, setAllManufacturersState] = useState([]);
  // FUNCTIONS
  const updateUserId = (value) => setUserIdState(value);

  const getAllProducts = useCallback(async (value) => {
    try {
      const response = await axios.get(`/products/all/${value}`);
      console.log(response.data);
      setAllProductsState([...response.data]);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }, []);

  const getAllDepartments = useCallback(async (value) => {
    try {
      const response = await axios.get(`/products/allDepartments`);
      setAllDepartmentsState([...response.data]);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }, []);

  const getAllCategories = useCallback(async (value) => {
    try {
      const response = await axios.get(`/products/allCategories`);
      setAllCategoriesState([...response.data]);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }, []);

  const getAllManufacturers = useCallback(async (value) => {
    try {
      const response = await axios.get(`/products/allManufacturers`);
      setAllManufacturersState([...response.data]);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }, []);

  // USE EFFECT
  useEffect(() => {
    const fetchData = async () => {
      await getAllProducts();
      await getAllDepartments();
      await getAllCategories();
      await getAllManufacturers();
      setLoading(false);
    };
    fetchData();
  }, [getAllProducts, getAllDepartments, getAllCategories, getAllManufacturers]);

  // FUNCTIONS
  const getDesiredSkuInfo = (type, value) => {
    const valueString = value ? value.toString().toLowerCase() : 0;
    console.log('valueString: ' + valueString);
    const valueNumber = parseInt(value);
    let results = [];
    switch (type) {
      case 'desc':
        results = getAllProductsState.filter((e) =>
          e.description.toLowerCase().includes(valueString)
        );
        break;
      case 'deptid':
        results = getAllProductsState.filter((e) => e.product_department_id === valueNumber);
        break;
      case 'category':
        results = getAllProductsState.filter((e) => e.product_category_id === valueNumber);
        break;
      case 'mfrid':
        results = getAllProductsState.filter((e) => e.product_manufacturer_id === valueNumber);
        break;
      case 'id':
        results = getAllProductsState.filter((e) => e.product_id === valueNumber);
        break;
      case 'code':
        results = getAllProductsState.filter((e) => e.product_code === valueString);
        break;
      case 'fuzzy':
        results = getAllProductsState.filter(
          (e) =>
            e.product_id === valueNumber ||
            e.product_code === valueString ||
            (e.upc_code.includes(valueString) && valueString.length > 9) ||
            e.description.toLowerCase().includes(valueString)
        );
        break;
      case 'nocategory':
        results = getAllProductsState.filter((e) => e.product_category_id === null);
        break;
      case 'worn':
        results = getAllProductsState.filter(
          (e) => e.product_manufacturer_id === 250 || e.product_manufacturer_id === 555
        );
        break;
      case 'notv':
        results = getAllProductsState.filter(
          (e) => e.truevalue_id === null && [1, 2].includes(e.product_type_id)
        );
        break;
      case 'nocoo':
        results = getAllProductsState.filter((e) => e.country_of_origin === null);
        break;

      default:
        results = [];
    }
    return results;
  };

  const getCategory26 = () => {
    const result = getAllProductsState.filter((e) => e.product_category_id === 26);
    return result;
  };

  const getRuger = () => {
    const result = getAllProductsState.filter((e) => e.product_manufacturer_id === 369);
    return result;
  };

  const getByItemType = (id) => {
    const result = getAllProductsState.filter((e) => e.product_type_id === id);
    return result;
  };

  // CONTEXT OBJECT TO PASS THROUGH PROVIDER
  const contextValue = {
    userId: userIdState,
    products: getAllProductsState,
    getAllProducts,
    updateUserId,
    getCategory26,
    getRuger,
    getByItemType,
    getDesiredSkuInfo,
    allDepartments: allDepartmentsState,
    allCategories: allCategoriesState,
    allManufacturers: allManufacturersState,
  };

  if (loading) {
    return <div>...Context Loading</div>;
  }
  console.log('getAllProductsState:', getAllProductsState);
  return (
    <ProductDataContextState.Provider value={contextValue}>
      {children}
    </ProductDataContextState.Provider>
  );
};

export default ProductDataContext;
