import { Route, Routes } from 'react-router-dom';
import React from 'react';
import ProductDataDashboard from '../Components/ProductDataManager/Dashboard/Dashboard';
import ProductDataContext from '../Context/Marketing/ProductData/ProductDataContext';

const ProductDataManagerRoute = () => {
	return (
		<ProductDataContext>
			<Routes>
				<Route path='/*' element={<ProductDataDashboard/>} />
			</Routes>
		</ProductDataContext>
	);
};

export default ProductDataManagerRoute;
