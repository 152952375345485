import { Grid2 } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { PublicationContextV2State } from '../../../../../Context/Marketing/PublicationContextV2';
import { MediaKitContextState } from '../MediaKitContext';

import PublicationCard from './PublicationCard';

const PublicationList = ({ publications }) => {
  // CONTEXT STATE
  const { allPublications } = useContext(PublicationContextV2State);
  const { year, month, type } = useContext(MediaKitContextState);

  // LOCAL STATE
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);

  // USE EFFECT
  useEffect(() => {
    const proxyMonth = month === 0 ? 1 : month;
    const startMonth = proxyMonth < 10 ? `0${proxyMonth}` : proxyMonth;
    const nextMonth =
      proxyMonth === 12 ? `01` : proxyMonth + 1 < 10 ? `0${proxyMonth + 1}` : proxyMonth + 1;
    const startDate = `${year}-${startMonth}-00`;
    const endDate =
      proxyMonth === 12 || month === 0 ? `${year + 1}-01-01` : `${year}-${nextMonth}-01`;

    // Filter by date
    let tempArray = allPublications.filter(
      (pub) => pub.start_date > startDate && pub.start_date < endDate
    );

    // Filter by type if necessary
    if (type !== 0) {
      tempArray = tempArray.filter((e) => e.ss_publication_type_id === type);
    }

    setData(tempArray);
    setLoading(false);
  }, [year, month, type, allPublications]);

  // LOADING FUNCTION
  if (loading) {
    return <div>...Loading PublicationList</div>;
  }
  console.log('allPublications: ', allPublications);
  return (
    <Grid2 container spacing={3} sx={{ height: '100%', overflowY: 'auto', pb: 4 }}>
      {data.map((pub, idx) => (
        <PublicationCard key={idx} pub={pub} />
      ))}
    </Grid2>
  );
};

export default PublicationList;
