import React from 'react';
import axios from 'axios';

export const products = async (type, value) => {
  try {
    const response = await axios.get(`/products/all`);
    return response.data;
  } catch (error) {
    console.log('Error fetching data:', error);
    return error;
  }
};
