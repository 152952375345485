import React, { useContext, useEffect, useState, useMemo } from 'react';
// IMPORT CONTEXTS
import { ContractContextState } from '../../../../Context/Marketing/ContractContext';
import { PublicationContextV2State } from '../../../../Context/Marketing/PublicationContextV2';
import { EntityContactContextState } from '../../../../Context/Entity/EntityContacts/EntityContactContext';
import { EntityContextState } from '../../../../Context/Entity/EntityContext';

import {
  insertOrUpdateContracts,
  getAllBrandsFromParentEntityIdThatHasProducts,
} from '../ContractsMiddle';

// MUI IMPORT
import {
  Box,
  Button,
  Chip,
  MenuItem,
  styled,
  TextField,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
//import ContractCardEditContainer from './ContractCardEditContainer';
import { Interests, SaveAlt, SendAndArchive } from '@mui/icons-material';
import DeleteButton from './components/DeleteButton';
import AddBrand from './components/AddBrand';

const imageURL =
  'https://plus.unsplash.com/premium_photo-1667239113306-4c875e7706ca?q=80&w=2667&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D';
// Material Styled Components
const Card = styled(Box)(({ theme }) => ({
  position: 'relative',
  background:
    'linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(235,231,231,1) 50%, rgba(255,255,255,1) 100%)',
  padding: '24px',
  borderRadius: '24px',
  border: `solid px ${theme.palette.primary.main}`,
  display: 'flex',
  flexDirection: 'column',
  boxShadow: `2px 2px 8px ${theme.palette.primary.main}66`,
  '.header': {
    position: 'relative',
    backgroundImage: `url(${imageURL})`,
    backgroundPosition: 'center top',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    aspectRatio: '21/9',
    borderRadius: '24px',
    backgroundColor: 'teal',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    boxShadow: `2px 2px 8px ${theme.palette.primary.main}66`,
    '&::before': {
      content: '""',
      backgroundColor: `${theme.palette.primary.main}aa`,
      position: 'absolute',
      display: 'block',
      width: '100%',
      height: '100%',
      borderRadius: '24px',
      border: `solid 2px ${theme.palette.primary.main}`,
    },
    '& p': {
      position: 'relative',
      margin: 0,
      textAlign: 'center',
      fontWeight: 'bold',
      fontSize: '1.15em',
    },
  },
  '.content-wrapper': {
    padding: '24px 8px',
    flex: '1',
    display: 'flex',
    gap: '16px',
  },
  '.dates': {
    width: '100px',
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
    paddingTop: '8px',
    '.date-box': {
      '& > *:nth-child(1)': {
        color: theme.palette.gray.dark,
        fontWeight: 'bold',
      },
      '& > *:nth-child(2)': {
        color: theme.palette.gray.medium,
        fontWeight: 'bold',
      },
    },
  },
  '.content': {
    flex: '1',
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
  },
}));

// PRIMARY COMPONENT
const ContractCard = ({ contractDetails, pubDetails }) => {
  // INIT's
  const theme = useTheme();

  // CONTEXT STATE
  const { allPublications, allPublicationProducts } = useContext(PublicationContextV2State);
  const { allEntityContacts } = useContext(EntityContactContextState);
  const { allEntities } = useContext(EntityContextState);
  const { allContracts, allContractStatuses, setAllContracts } = useContext(ContractContextState);

  // LOCAL STATE
  const [fetchDataLoaded, setFetchDataLoaded] = useState(false);
  const [fetchBrandsLoaded, setFetchBrandsLoaded] = useState(false);
  const [cardData, setCardData] = useState({});
  const [entityBrands, setEntityBrands] = useState([]);

  const handleStatusChange = (statusId) => {
    const updatedCardData = { ...cardData, ss_publication_contract_status_id: statusId };
    handleUpdatingContract(updatedCardData);
  };

  const handleProductChange = (e) => {
    const value = e.target.value === 0 ? null : e.target.value;
    const updatedCardData = { ...cardData, ss_publication_product_id: value };
    handleUpdatingContract(updatedCardData);
  };

  const handleDelete = () => {
    const updatedCardData = { ...cardData, deleted: 1, ss_publication_contract_status_id: 5 };
    handleUpdatingContract(updatedCardData);
  };

  const handleDeleteBrand = (brandId) => {
    const existingBrands = cardData.product_manufacturers.split(',').map(Number);
    const updatedBrands = existingBrands.filter((e) => e !== brandId);
    const updatedCardData = { ...cardData, product_manufacturers: updatedBrands.join(',') };
    handleUpdatingContract(updatedCardData);
  };

  const handleUpdatingContract = async (data) => {
    try {
      const res = await insertOrUpdateContracts(data);
      res.affectedRows > 0 && setAllContracts();
    } catch (error) {
      console.log('error handleUpdatingContract: ', error.message);
    }
  };

  useEffect(() => {
    const fetch = () => {
      try {
        const filteredData = allContracts.filter(
          (e) => e.ss_publication_contract_id === contractDetails.ss_publication_contract_id
        );
        const pubDetails = allPublications.filter(
          (e) => e.ss_publication_id === filteredData[0].ss_publication_id
        );
        setCardData({
          ...filteredData[0],
          ss_publication_type_id: pubDetails[0].ss_publication_type_id,
        });
        setFetchDataLoaded(true);
      } catch (error) {
        console.log('error: ', error.message);
      }
    };
    allContracts && allContracts.length > 0 && fetch();
  }, [allContracts, allPublications, contractDetails]);

  useEffect(() => {
    const fetchBrandIds = async () => {
      try {
        const getBrandData = await getAllBrandsFromParentEntityIdThatHasProducts(
          cardData.entity_id
        );
        const brandIds = await getBrandData.map((e) => e.entity_id);
        const filteredEntities = allEntities.filter((e) => brandIds.includes(e.entity_id));
        setEntityBrands([...filteredEntities]);
      } catch (error) {
        console.log('error getAllBrandsFromParentEntityIdThatHasProducts: ', error.message);
      }
    };
    allContracts && allContracts.length > 0 && fetchBrandIds();
    setFetchBrandsLoaded(true);
  }, [cardData.entity_id, allEntities, allContracts]);

  const contractStatus = useMemo(() => {
    return allContractStatuses.find(
      (p) =>
        p.ss_publication_contract_status_id ===
        parseInt(cardData?.ss_publication_contract_status_id)
    );
  }, [allContractStatuses, cardData]);

  const productsByType = useMemo(() => {
    const filteredProducts = allPublicationProducts.filter((p) => {
      if (p.types) {
        const typesArray = p.types.split(',');
        return typesArray.includes(String(cardData.ss_publication_type_id));
      }
      return false;
    });

    return [
      { ss_publication_product_id: 0, description: 'No Selected Product' },
      ...filteredProducts,
    ];
  }, [allPublicationProducts, cardData]);

  const entityContacts = useMemo(() => {
    return allEntityContacts.find((p) => p.user_id === parseInt(cardData?.user_id));
  }, [allEntityContacts, cardData]);

  return (
    <>
      {fetchDataLoaded && fetchBrandsLoaded && (
        <Card>
          <Box className='header'>&nbsp;</Box>

          <Box className='content-wrapper'>
            <Box className='dates'>
              <Box className='date-box'>
                <Box>Starts:</Box>
                <Box>{pubDetails[0].start_date}</Box>
              </Box>

              <Box className='date-box'>
                <Box>Expires:</Box>
                <Box>{pubDetails[0].expiration_date}</Box>
              </Box>

              <Box className='date-box'>
                <Box>Art Due:</Box>
                <Box>{pubDetails[0].submit_art_date}</Box>
              </Box>
            </Box>

            <Box className='content'>
              <Box
                sx={{ fontWeight: 'bold', color: theme.palette.primary.main, fontSize: '1.2em' }}
              >
                {pubDetails[0].description}
              </Box>
              <Box sx={{ color: theme.palette.gray.extraDark }}>
                {pubDetails[0].detailed_description}
              </Box>
            </Box>
          </Box>

          <Box
            sx={{
              color: theme.palette.gray.extraDark,
              fontWeight: 'bold',
              textAlign: 'left',
              mt: '8px',
              mb: '32px',
            }}
          >
            <TextField
              fullWidth
              select
              label='Publication Products'
              value={contractDetails.ss_publication_product_id}
              onChange={(e) => handleProductChange(e)}
            >
              {productsByType.map((option) => (
                <MenuItem
                  key={option.ss_publication_product_id}
                  value={option.ss_publication_product_id}
                >
                  {option.ss_publication_product_id === 0
                    ? option.description
                    : `${option.description} - ${option.standard_price}`}
                </MenuItem>
              ))}
            </TextField>
          </Box>

          <Box
            sx={{
              mb: '24px',
              padding: '16px 8px',
              borderTop: `solid 2px ${theme.palette.primary.light}`,
              borderBottom: `solid 2px ${theme.palette.primary.light}`,
              display: 'flex',
              gap: '16px',
              flexWrap: 'wrap',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            {allEntities
              .filter((e) => cardData.product_manufacturers.includes(e.entity_id))
              .map((e, i) => (
                <Chip
                  sx={{ fontWeight: 'bold' }}
                  key={e.entity_id}
                  label={e.business_name}
                  color='secondary'
                  onDelete={() => handleDeleteBrand(e.entity_id)}
                />
              ))}
            <AddBrand
              cardData={cardData}
              entityBrands={entityBrands}
              handleUpdatingContract={handleUpdatingContract}
            />
          </Box>

          <Box
            sx={{
              fontWeight: 'bold',
              fontSize: '1.1em',
              mb: '24px',
              textAlign: 'center',
              color:
                cardData.ss_publication_contract_status_id === 1
                  ? theme.palette.success.main
                  : theme.palette.warning.main,
            }}
          >
            Status:
            <span style={{ margin: '0 4px' }}>{contractStatus.description}</span>(
            <span style={{ color: theme.palette.primary.light, margin: '0 4px' }}>
              {`${entityContacts.first_name} ${entityContacts.last_name}`}
            </span>
            )
          </Box>

          <Box
            sx={{
              display: 'flex',
              gap: '16px',
              justifyContent: 'flex-start',
              alignItems: 'center',
              border: `solid 2px ${theme.palette.primary.main}`,
              backgroundColor: theme.palette.primary.light,
              p: '8px',
              borderRadius: '16px',
            }}
          >
            <DeleteButton cardData={cardData} handleDelete={handleDelete} />

            <Tooltip title="Manage Product Sku's Associated to Contract">
              <Button sx={{ display: 'flex', flexDirection: 'column', color: 'white' }}>
                <Interests
                  sx={{ filter: `drop-shadow(4px 4px 3px ${theme.palette.primary.main})` }}
                />
                <Typography
                  sx={{
                    fontSize: '1em',
                    fontWeight: 'bold',
                    filter: `drop-shadow(4px 4px 3px ${theme.palette.primary.main})`,
                  }}
                >
                  SKU's
                </Typography>
              </Button>
            </Tooltip>

            <Tooltip title='Save Contract For Later Submission'>
              <Button
                sx={{ ml: 'auto', display: 'flex', flexDirection: 'column', color: 'white' }}
                onClick={() => handleStatusChange(4)}
              >
                <SaveAlt
                  sx={{ filter: `drop-shadow(4px 4px 3px ${theme.palette.primary.main})` }}
                />
                <Typography
                  sx={{
                    fontSize: '1em',
                    fontWeight: 'bold',
                    filter: `drop-shadow(4px 4px 3px ${theme.palette.primary.main})`,
                  }}
                >
                  Save
                </Typography>
              </Button>
            </Tooltip>

            <Tooltip title="Submit Contract to Sports South's Marketing">
              <Button
                sx={{ display: 'flex', flexDirection: 'column', color: 'white' }}
                onClick={() => handleStatusChange(1)}
              >
                <SendAndArchive
                  sx={{ filter: `drop-shadow(4px 4px 3px ${theme.palette.primary.main})` }}
                />
                <Typography
                  sx={{
                    fontSize: '1em',
                    fontWeight: 'bold',
                    filter: `drop-shadow(4px 4px 3px ${theme.palette.primary.main})`,
                  }}
                >
                  Submit
                </Typography>
              </Button>
            </Tooltip>
          </Box>
        </Card>
      )}
    </>
  );
};

export default ContractCard;
