import { Description } from '@mui/icons-material';
import { Box, Button, Divider } from '@mui/material';
import React from 'react';

const PublicationCardInsert = ({ pubData, color, pubType }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: 'white',
        position: 'absolute',
        bottom: 0,
        left: 0,
        width: '100%',
        height: '50%',
        transition: '300ms ease-in-out',
        transformOrigin: 'bottom center',
        '&:hover': {
          height: '100%',
          '& .detailed-description': {
            height: '200px',
          },
        },
      }}
    >
      <Box
        sx={{
          fontWeight: 'bold',
          fontSize: '1.4em',
          p: 2,
          pt: 5,
          position: 'relative',
          lineHeight: '1.1',
        }}
      >
        {pubData.description}

        <Box
          sx={{
            color: color,
            display: 'flex',
            alignItems: 'center',
            gap: '4px',
            fontSize: '.6em',
            position: 'absolute',
            top: '16px',
            left: '12px',
          }}
        >
          <Description sx={{ fontSize: '1.5em' }} />
          <span>{pubType}</span>
        </Box>

        <Box
          className='detailed-description'
          sx={{ height: 0, overflow: 'hidden', transition: '300ms ease-in-out' }}
        >
          <Divider sx={{ my: 2 }} />
          <span style={{ fontSize: '16px' }}>{pubData.detailed_description}</span>
          <Divider sx={{ my: 2 }} />
        </Box>
      </Box>
      <Box sx={{ flex: 1 }}>&nbsp;</Box>
      <Box sx={{ pb: 2, textAlign: 'center' }}>
        <Button variant='contained' sx={{ backgroundColor: color }}>
          Create Contract
        </Button>
      </Box>
    </Box>
  );
};

export default PublicationCardInsert;
