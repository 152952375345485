import { Box, Button, Divider, Grid2, IconButton } from '@mui/material';
import React, { useContext } from 'react';
import { PublicationContextV2State } from '../../../../../Context/Marketing/PublicationContextV2';
import { Description } from '@mui/icons-material';
import PublicationCardInsert from './PublicationCardInsert';

const colors = [
  { id: 1, color: '#cc0000' },
  { id: 2, color: '#00ACC1' },
  { id: 3, color: '#8E24AA' },
  { id: 4, color: '#D81B60' },
  { id: 5, color: '#5E35B1' },
  { id: 6, color: '#FF7043' },
  { id: 7, color: '#1E88E5' },
  { id: 8, color: '#43A047' },
];

const PublicationCard = ({ pub }) => {
  // INIT's
  const { allPublicationTypes } = useContext(PublicationContextV2State);

  return (
    <Grid2
      sx={{
        borderRadius: '16px',
        position: 'relative',
        backgroundColor: colors.find((color) => color.id === pub.ss_publication_type_id).color,
        boxShadow: '1px 2px 4px rgba(0,0,0,.2)',
        aspectRatio: '3/4',
        transition: '300ms ease-in-out',
        overflow: 'hidden',
      }}
      size={{ xs: 12, sm: 6, lg: 4 }}
    >
      <img
        style={{
          position: 'absolute',
          top: '24px',
          left: '50%',
          transform: 'translateX(-50%)',
          objectFit: 'cover',
          display: 'block',
          width: '40%',
          aspectRatio: '3/4',
          boxShadow: '4px 6px 8px rgba(0,0,0,.2)',
        }}
        src='/images/publications/turkey_hunting_portrait.jpg'
        alt='my publication'
      />

      <PublicationCardInsert
        pubData={pub}
        color={colors.find((color) => color.id === pub.ss_publication_type_id).color}
        pubType={
          allPublicationTypes.find((e) => e.ss_publication_type_id === pub.ss_publication_type_id)
            .description
        }
      />
    </Grid2>
  );
};

export default PublicationCard;
